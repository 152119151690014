module controllers {
    export module documentManagement {
        interface IDocumentScope extends ng.IScope {
        }
        export class moduleDocumentCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', "bsLoadingOverlayService", "$state", "reportService", "type", "referenceId"];

            breadCrumbDesc: string;

            version: number;

            constructor(public $scope: IDocumentScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService, public $q: ng.IQService, public bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private reportService: interfaces.reporting.IReportService,
                private type: Enum.EnumModelType,
                private referenceId: number) {

                this.version = $state.params["version"];
            }

            loadDocuments() {
                return this.reportService.getDocumentList(this.type, this.referenceId).query(() => { },
                    (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                    }).$promise;
            }

        }

        angular.module("app").controller("moduleDocumentCtrl", controllers.documentManagement.moduleDocumentCtrl);
    }
}